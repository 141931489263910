import React, { useContext } from 'react'
import { FiPhoneCall, FiMapPin, FiClock } from 'react-icons/fi'
import { GoCalendar } from "react-icons/go";
import { GlobalDataContext } from '../../context/context'
import Form from './Form'



const ContactInfo2 = () => {

    const { rpdata } = useContext(GlobalDataContext)



    // const contentInfo = [
    //     {
    //         title: rpdata?.dbPrincipal?.workdays.length > 1 ? rpdata?.dbPrincipal?.workdays?.[0].day : 'Workdays',
    //         subTitle: rpdata?.dbPrincipal?.workdays.length > 1 ? rpdata?.dbPrincipal?.workHours?.[0].hour : rpdata?.dbPrincipal?.workdays?.[0].day,
    //         icon: <FaCalendarAlt fontSize={45} color={'#313131'} />,
    //     },
    //     {
    //         title: rpdata?.dbPrincipal?.workdays.length > 1 ? rpdata?.dbPrincipal?.workdays?.[1].day : 'Work Hours',
    //         subTitle: rpdata?.dbPrincipal?.workdays.length > 1 ? rpdata?.dbPrincipal?.workHours?.[1].hour : rpdata?.dbPrincipal?.workHours?.[0].hour,
    //         icon: <AiFillClockCircle fontSize={45} color={'#313131'} />,
    //     },
    // ]
    return (
        <>

            {/* <div className='w-[80%] mx-auto grid grid-cols-1 md:grid-cols-2 mt-10'>
                <div className='relative'>
                    <div className='absolute bgBloque w-[40%] h-[60%] -z-10'></div>
                    <div className='mt-10 ml-[30px] md:ml-[50px] px-5 md:px-[40px] pt-[80px] bg-white'>
                        <h2>Get In Touch!</h2>
                        <p className='pb-5'>{rpdata?.dbAbout?.[1].text}</p>
                        <div className='grid grid-cols-1 lg:grid-cols-2'>
                            <div className='flex mb-5'>
                                <div className='self-center'>
                                    <FiNavigation fontSize={45} color={'#313131'} />
                                </div>
                                <div className='self-center px-3'>
                                    <p className='text-[20px] font-normal'>Location</p>
                                    <p className='bgIconContact font-medium'>{rpdata?.dbPrincipal?.location?.[0].address}</p>
                                </div>
                            </div>
                            <div className='flex mb-5'>
                                <div className='self-center'>
                                    <FiPhoneCall fontSize={45} color={'#313131'} />
                                </div>
                                <div className='self-center px-3'>
                                    <p className='text-[20px] font-normal'>Phone</p>
                                    <p className='bgIconContact font-medium'>
                                        {
                                            rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                return (
                                                    <a
                                                        key={index}
                                                        href={`tel:+1${phone.phone}`}
                                                    >
                                                        <p className='text-[20px] font-normal'>{phone.phone}</p>
                                                    </a>
                                                )
                                            })
                                        }
                                    </p>
                                </div>
                            </div>
                            {
                                contentInfo.map((items, index) => {
                                    return (
                                        <div key={index} className='flex mb-5'>
                                            <div className='self-center'>
                                                {items.icon}
                                            </div>

                                            <div className='self-center px-3'>
                                                <p className='text-[20px] font-normal capitalize'>{items.title}</p>
                                                <p className='bgIconContact font-medium capitalize'>{items.subTitle}</p>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex flex-col ">
                            {
                                rpdata?.dbSocialMedia?.redes?.length > 0 ?
                                    <div>
                                        <h4 className='py-5'>Follow Us</h4>
                                        <ul className="flex space-x-7">
                                            {
                                                rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                                    return (
                                                        <li key={index} className="bgIconContact transition-all ease-in-out duration-300">
                                                            <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                                                <i
                                                                    className={`fab fa-${item.icon} text-[20px]`}
                                                                    aria-hidden="true"
                                                                />
                                                            </a>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className='mb-10'>
                    <div className='relative'>
                        <img
                            src={rpdata?.stock?.[12]}
                            alt='no found'
                            className='w-full h-[500px] object-cover pr-[30px] md:pr-[80px] mt-10 md:mt-[200px]'
                        />
                        <div className='absolute -bottom-14 -z-10 right-0 bgBloque w-[40%] h-full'></div>
                    </div>
                </div>
            </div> */}
            <div className="  w-full flex justify-center bg-[#ffffff] px-8">
                <div className="flex w-[1100px] lg:py-24 py-14 md:flex-row flex-col text-center md:text-start">
                    <div
                        className="md:w-[10%] w-full md:px-14 px-2 bgBloque mt-10 mb-10"
                    ></div>
                    <div className=" shadowCont md:w-[80%] md:flex block w-full md:px-14 py-4 lg:bg-white bg-transparent lg:px-3 px-0">
                        <div className='md:w-[40%] w-full h-auto flex flex-col bg-white justify-center items-start py-5 lg:py-0 lg:px-2 px-8'>
                            <h2>Get In Touch!</h2>
                            <ul className="pt-5">
                                {rpdata?.dbPrincipal?.location
                                    ?.slice(0, 1)
                                    .map((item, index) => {
                                        return (
                                            <li key={index} className="flex py-2">
                                                <FiMapPin className="bg-[#FDCA02] p-2 text-[40px] text-white rounded-md" />
                                                <span className="pl-3 font-bold text-[18px]">{item.address}</span>
                                            </li>
                                        );
                                    })}
                                {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a
                                                href={`tel:+1${item.phone}`}
                                                className="py-2 flex items-center"
                                            >
                                                <FiPhoneCall className="bg-[#FDCA02] p-2 text-[40px] text-white rounded-md" />
                                                <span className="pl-3 font-bold text-[18px]">{item.phone}</span>
                                            </a>
                                        </li>
                                    );
                                })}

                                {/* {rpdata?.dbPrincipal?.emails?.[0].email.length > 0 ? (
                                    <li>
                                        <a
                                            href={`mailto:${rpdata?.dbPrincipal?.emails[0].email}`}
                                            className="py-2 flex items-center"
                                        >
                                            <FiMail className="bg-[#FDCA02] p-2 text-[40px] text-white rounded-md" />
                                            <span className="pl-3">
                                                {rpdata?.dbPrincipal?.emails[0].email}
                                            </span>
                                        </a>
                                    </li>
                                ) : null} */}

                                {rpdata?.dbPrincipal?.workdays.length > 1 ? (
                                    <li className="py-2 flex items-center">
                                        <GoCalendar className="bg-[#FDCA02] p-2 text-[40px] text-white rounded-md" />
                                        <div className="flex flex-col">
                                            <span className="pl-3 font-bold text-[18px]">
                                                {rpdata?.dbPrincipal?.workdays?.[0].day}
                                            </span>
                                            <span className="pl-3 font-bold text-[18px]">
                                                {rpdata?.dbPrincipal?.workHours?.[0].hour}
                                            </span>
                                        </div>
                                    </li>
                                ) : (
                                    <li className="py-2 flex items-center">
                                        <GoCalendar className="bg-[#FDCA02] p-2 text-[40px] text-white rounded-md" />
                                        <span className="pl-3 font-bold text-[18px]">
                                            {rpdata?.dbPrincipal?.workdays?.[0].day}
                                        </span>
                                    </li>
                                )}
                                {rpdata?.dbPrincipal?.workdays.length > 1 ? (
                                    <li className="py-2 flex items-center">
                                        <FiClock className="bg-[#FDCA02] p-2 text-[40px] text-white rounded-md" />
                                        <div className="flex flex-col">
                                            <span className="pl-3 font-bold text-[18px]">
                                                {rpdata?.dbPrincipal?.workdays?.[1].day}
                                            </span>
                                            <span className="pl-3 font-bold text-[18px]">
                                                {rpdata?.dbPrincipal?.workHours?.[1].hour}
                                            </span>
                                        </div>
                                    </li>
                                ) : (
                                    <li className="py-2 flex items-center">
                                        <FiClock className="bg-[#FDCA02] p-2 text-[40px] text-white rounded-md" />
                                        <span className="pl-3 font-bold text-[18px]">
                                            {" "}
                                            {rpdata?.dbPrincipal?.workHours?.[0].hour}
                                        </span>
                                    </li>
                                )}

                            </ul>
                            <div className="flex flex-col ">
                                {
                                    rpdata?.dbSocialMedia?.redes?.length > 0 ?
                                        <div>
                                            <h5 className='py-5'>Follow Us</h5>
                                            <ul className="flex space-x-7">
                                                {
                                                    rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                                        return (
                                                            <li key={index} className="text-[#1757C9] transition-all ease-in-out duration-300">
                                                                <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                                                    <i
                                                                        className={`fab fa-${item.icon} text-[20px]`}
                                                                        aria-hidden="true"
                                                                    />
                                                                </a>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className='md:w-[60%] w-full h-auto lg:mt-0 mt-5 bg-white lg:p-0 p-5'>
                            <Form />
                        </div>
                    </div>
                    <div
                        className="md:w-[10%] w-full md:px-14 px-2 bgBloque mt-10 mb-10"
                    ></div>
                </div>
            </div>
        </>
    )
}

export default ContactInfo2